/**
 * Back to top
 */

/**
 * Add body class .has-no-hero-background
 */
const annimateTextFloat = () => {
	if (document.querySelector('.textfloat')) {
		document.addEventListener('DOMContentLoaded', function () {
			const textfloatTel = document.getElementsByClassName('textfloat__tel')

			if (!textfloatTel.length) {
				return
			}

			const trigger = document.querySelectorAll('.blocks .bloc-tel')

			if (trigger.length > 0) {
				const textfloatTelToggle = function () {
					if (
						trigger[0].getBoundingClientRect().top + trigger[0].scrollHeight <
						0
					) {
						textfloatTel[0].classList.add('show')
					} else if (textfloatTel[0].classList.contains('show')) {
						textfloatTel[0].classList.remove('show')
					}
				}

				textfloatTelToggle()

				// eslint-disable-next-line @wordpress/no-global-event-listener
				window.addEventListener('scroll', function () {
					textfloatTelToggle()
				})
			}
		})
		document.addEventListener('DOMContentLoaded', function () {
			const textfloatSocial =
				document.getElementsByClassName('textfloat__social')

			if (!textfloatSocial.length) {
				return
			}

			const trigger = document.querySelectorAll('.blocks .bloc-social')

			if (trigger.length > 0) {
				const textfloatSocialToggle = function () {
					if (
						trigger[0].getBoundingClientRect().top + trigger[0].scrollHeight <
						0
					) {
						textfloatSocial[0].classList.add('show')
					} else if (textfloatSocial[0].classList.contains('show')) {
						textfloatSocial[0].classList.remove('show')
					}
				}

				textfloatSocialToggle()

				// eslint-disable-next-line @wordpress/no-global-event-listener
				window.addEventListener('scroll', function () {
					textfloatSocialToggle()
				})
			}
		})
	}
}
annimateTextFloat()
