document.addEventListener('DOMContentLoaded', function () {
	var popupsTrigger = document.querySelectorAll('.popup-trigger, .popup-close')
	if (popupsTrigger.length) {
		popupsTrigger.forEach(function (popupTrigger) {
			popupTrigger.addEventListener(
				'click',
				function (event) {
					event.preventDefault()
					var popup = document.querySelector('.' + popupTrigger.dataset.popup)
					popup.classList.toggle('open')

					if (popup.classList.contains('popup-openonload')) {
						Cookies.set(popupTrigger.dataset.popup, 'true', {
							expires: 2,
						})
					}
				},
				false
			)
		})
	}

	var popupsOpenonload = document.querySelectorAll('.popup-openonload')
	if (popupsOpenonload.length) {
		popupsOpenonload.forEach(function (popupOpenonload) {
			var cookie = Cookies.get(popupOpenonload.dataset.popupid)
			if (!cookie) {
				popupOpenonload.classList.toggle('open')
			}
		})
	}
})
